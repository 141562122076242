import React, { useState, useEffect } from 'react'

import { 
    useTableCount, 
    useTableSelectedRows, 
    useTableSelectAllState
} from '../../../redux/selectors'

import { Link, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { 
    Button, 
    DialogTitle, 
    DialogContent, 
    DialogContentText, 
    DialogActions, 
    Popper, 
    MenuItem, 
    ListItemIcon, 
    ListItemText, 
    ClickAwayListener, 
    Paper
} from '@material-ui/core'
import BPMDialog from './Dialogs/BPMDialog'
import { withStyles } from '@material-ui/core/styles'
import { AddCircle as AddCircleIcon} from '@material-ui/icons'

import { TableHeaderTooltip } from '../../Tooltips/BPMTooltips'
import DeleteAccount from './Mutation/Delete'
import ExcelFileTask from './ExcelFileTask'
import { more_vertical_SVG  } from './Icons/NavigationSVGs'
import MassiveUploadDialog from './Dialogs/MassiveUploadDialog'

const styles = theme => ({
    root: {
        width: '100%'
    },
    chinDiv: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        height:30,
        left:'10%',
        textAlign:'center',
        width:'80%',
        backgroundColor: '#0a1f2eeb',
        borderRadius:'5px 5px 0px 0px',
        position:'fixed',
        bottom:'0px'
    },
    selectedItems: {
        color:'white',
        fontWeight:800,
        verticalAlign: 'top'
    },
    addition: {
        color:'white',
        fontWeight:800
    },
    totalCount: {
        color:'white',
        fontWeight:800,
        display:'inline',
        marginTop:'2px'
    },
    tableTittle: {
        color:'#4aaae1',
        fontWeight:800,
        display:'inline-block',
        float:'left',
        paddingLeft:20,
        fontSize : 20
    },
    spacingBetweenElements:{
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    normalDisable : {
        backgroundColor: "#4aaae191"
    },
    moreTools : {
        float:'right',
        paddingRight:20,
        marginTop: 2,
        cursor:'pointer',
        borderRadius: '50%',
        height: 25,
        width: 25,
        '&:hover': {
            backgroundColor: '#00000070'
        }
    }
})

const CustomAdditionButton = withStyles(theme => ({
    root: {
        padding: '0px 5px',
        fontWeight: 700,
        color: 'white',
        marginTop: '5px',
        height: '20px',
        width: 90,
        backgroundColor: '#4aaae1',
        '&:hover': {
            backgroundColor: '#94c5e1'
        }
    },
    disabled: {
        backgroundColor: '#4aaae180'
    }
}))(Button);

const CustomSelectedButton = withStyles(theme => ({
    root: {
        padding: '0px 5px',
        fontWeight: 700,
        color: 'white',
        margin: '2px 0',
        height: '20px',
        backgroundColor: '#4aaae1',
        '&:hover': {
            backgroundColor: '#94c5e1'
        }
    },
    label:{
        fontSize:10
    },
    disabled: {
        backgroundColor: '#4aaae180'
    }
}))(Button);

const TableFooter = (props) => {
    const {
        clearSelections, 
        filters,
        client, 
        TableObject, 
        classes,
        newButtonDisplay, 
        checkboxDisplay, 
        handleSetFilters, 
        t,
        refreshTable
    } = props

    const [statusDialog, setStatusDialog] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(null)
    const [moreToolsOptions, setMoreToolsOptions] = useState(null)
    const [exportDialog, setExportDialog] = useState(false)
    const [massiveUploadsDialog, setMassiveUploadsDialog] = useState(false)
    const [mounted, setMounted] = useState(true)
    const [snakBarCursor, setSnakBarCursor] = useState(null)

    const count = useTableCount(TableObject.tableName)
    const selected = useTableSelectedRows(TableObject.tableName)
    const allRowsSelected = useTableSelectAllState(TableObject.tableName)

    const history = useHistory();

    useEffect(() => {
        setMounted(true)

        return () => {
            setMounted(false);
        };
    }, []);

    const handleMessage = (m) => {
        createSnackMessage(m, 'success', null)
    }

    const handleErrorMessage = (m) => {
        createSnackMessage(m, 'error', null)
    }

    const createSnackMessage = (m, variant, redirectLink) => {
        TableObject.addApplicationSnackMessage({
            text: m, 
            variant: variant,
            duration: 8000,
            snakBarCursor: snakBarCursor,
            clckRedirect: redirectLink
        })
    }

    const handleClickOpenExportDialog = () => {
        setExportDialog(true)
    }

    const handleCloseExportDialog = () => {
        setExportDialog(false)
    }

    const handleClickOpenStatusDialog = () => {
        setStatusDialog(true)
    }

    const handleCloseStatusDialog = () => {
        setStatusDialog(false)
    }

    const handleMassiveUploadsDialog = () => {
        setMassiveUploadsDialog(!massiveUploadsDialog)
    }

    const handleOpenSelectedOptions = (event) => {
        setSelectedOptions(event.currentTarget)
    }

    const handleCloseSelectedOptions = () => {
        setSelectedOptions(null)
    }

    const handleOpenMoreToolsOptions  = (event) => {
        setMoreToolsOptions(event.currentTarget)
    }

    const handleCloseMoreToolsOptions  = () => {
        if (mounted) {
            setMoreToolsOptions(null)
        }
    }

    const handleGoToBudgets  = () => {
        history.push('/budget')
    }

    const handleQueryExecuted  = (success, message) => {
        setSnakBarCursor(success ? {cursor: 'pointer'} : null);
        if (success) {
            createSnackMessage(message, 'success', '/excelExports')
        } else {
            createSnackMessage(message, 'error', '/excelExports')
        }
        handleCloseExportDialog();
    }

    const getSelectedCount = () => {
        if (!selected) return 0
        return allRowsSelected ? count - selected.length : selected.length;
    }

    const getFilters = () => {
        const fltr = JSON.parse(JSON.stringify(filters));
        delete fltr.first;
        return fltr;
    }
    
    const getSelectedIds = () => {
        return selected.map((selectedData) => selectedData.id)
    }

    const footerItemsFunctions = {
        handleClickOpenExportDialog: handleClickOpenExportDialog,
        handleCloseExportDialog: handleCloseExportDialog,
        handleClickOpenStatusDialog: handleClickOpenStatusDialog,
        handleCloseStatusDialog: handleCloseStatusDialog,
        handleMassiveUploadsDialog: handleMassiveUploadsDialog,
        handleOpenSelectedOptions: handleOpenSelectedOptions,
        handleCloseSelectedOptions: handleCloseSelectedOptions,
        handleOpenMoreToolsOptions: handleOpenMoreToolsOptions,
        handleCloseMoreToolsOptions: handleCloseMoreToolsOptions,
        handleGoToBudgets: handleGoToBudgets
    }

    return (
        <div key='tblFooter_01'>
            <div key="tfl_000" className={classes.chinDiv}>
                <div key="tfl_001">
                    <span className={classes.tableTittle} style={{fontWeight: 700}}>{t("tableNames:" + TableObject.tableName)}</span>
                </div>

                <div key="tfl_002" style={{paddingTop: 3}}>
                    <div key="tfl_012" className={classes.spacingBetweenElements}>
                        <span className={classes.totalCount} style={{fontSize: '14px', fontWeight: 700}}>{t("tableFooter:Total Results") + ': ' + count}</span>
                        <div>
                            {
                                checkboxDisplay &&
                                <CustomSelectedButton
                                    className={classes.selectedItems}
                                    onClick={event => handleOpenSelectedOptions(event)}
                                    variant='outlined'
                                    disabled={getSelectedCount() === 0}
                                >
                                    <span>{t('tableFooter:Selected')}: {getSelectedCount()}</span>
                                </CustomSelectedButton>
                            }
                        </div>
                    </div>
                </div>

                <div key="tfl_003">
                    {   newButtonDisplay &&
                        <Link to='new' style={{ textDecoration: 'none'}}>
                            <CustomAdditionButton className={classes.addition} variant="outlined">
                                <span style={{paddingBottom: 1, paddingRight: 8, fontSize: 10}}>
                                    {t('tableFooter:New')}
                                </span>
                                <AddCircleIcon fontSize='small' style={{padding: '2px 0'}} />
                            </CustomAdditionButton>
                        </Link>
                    }

                    { !newButtonDisplay && TableObject.getFooterItems().length > 0 &&
                        <Button 
                            onClick={() => TableObject.getFooterItems()[0].onClick(footerItemsFunctions)} 
                            style={{
                                padding: '0px 5px',
                                fontWeight: 900,
                                color: 'white',
                                marginTop: '5px',
                                height: '20px',
                                width: "auto",
                            }}
                        >
                            {TableObject.getFooterItems()[0].icon}{t(`tableFooter:${TableObject.getFooterItems()[0].label}`)}
                        </Button>
                    }

                    {
                        (newButtonDisplay ||  TableObject.getFooterItems().length > 1) &&
                        <TableHeaderTooltip title={t('More Tools')} placement={'right'}>
                            <div className={classes.moreTools} onClick={event => handleOpenMoreToolsOptions(event)}>
                                {more_vertical_SVG}
                            </div>
                        </TableHeaderTooltip>
                    }
                </div>
            </div>

            <MassiveUploadDialog
                open={massiveUploadsDialog}
                handleClose={handleMassiveUploadsDialog}
                tableName={TableObject.massiveUploadName}
                urlContext={TableObject.massiveUploadTemplateUrlContext}
            />

            <BPMDialog
                key='dlg_a'
                open={statusDialog}
                onClose={handleCloseStatusDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {t('tableNames:'+TableObject.tableName) + ' ' + t('tableFooter:Status Modification')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {t('tableFooter:Confirm changing state of') + ` ${getSelectedCount()} ${t('tableNames:' + TableObject.tableName)}.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button key='ftr_btn_a' onClick={handleCloseStatusDialog} variant='contained'>{t('tableFooter:Cancel')}</Button>
                    <Button key='ftr_btn_b' 
                        onClick={() => {
                            DeleteAccount(getSelectedIds(), client, TableObject.deleteMutator, refreshTable, allRowsSelected, getFilters())
                            handleCloseStatusDialog()
                        }}
                        variant='contained' 
                        color='secondary'  
                        autoFocus
                    >
                        {t('tableFooter:Confirm')} 
                    </Button>
                </DialogActions>
            </BPMDialog>

            <BPMDialog
                key='dlg_b'
                open={exportDialog}
                onClose={handleClickOpenExportDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {`${t('tableNames:' + TableObject.tableName)} ` + t('tableFooter:Excel Download')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {t('tableFooter:Excel File is generated from applied filters')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div>
                        <ExcelFileTask TableObject={TableObject} filters={getFilters()} handleClose={handleQueryExecuted} />
                    </div>
                    <Button onClick={handleCloseExportDialog} variant='contained'>
                        {t('tableFooter:Cancel')}
                    </Button>
                </DialogActions>
            </BPMDialog>

            <Popper
                anchorEl={selectedOptions}
                placement="top"
                open={Boolean(selectedOptions)}
            >
                <ClickAwayListener onClickAway={handleCloseSelectedOptions}>
                    <Paper>
                        <MenuItem key="placeholder" style={{display: 'none'}} />
                        {TableObject.selectedRowsActions({
                            closePopper: handleCloseSelectedOptions,
                            changeSelectedState: handleClickOpenStatusDialog,
                            clearAllSelections: clearSelections,
                            selectedRows: selected,
                            allRowsSelected: allRowsSelected,
                            filters: getFilters(),
                            t, 
                            MenuItem,
                            ListItemIcon,
                            ListItemText,
                            handleSetFilters,
                            handleErrorMessage: handleErrorMessage,
                            handleMessage: handleMessage,
                            refreshTable: refreshTable,
                            tableCount: count
                        })}
                    </Paper>
                </ClickAwayListener>
            </Popper>

            <Popper
                anchorEl={moreToolsOptions}
                open={Boolean(moreToolsOptions)}
                placement='top'
            >
                <ClickAwayListener onClickAway={handleCloseMoreToolsOptions}>
                    <Paper>
                    {TableObject.getFooterItems().map((footerItems, index)=> ((!newButtonDisplay && index > 0) || newButtonDisplay) && footerItems.doFor(footerItemsFunctions, TableObject, t, index))}    
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>

    )
}

export default withTranslation()(withStyles(styles)(TableFooter))