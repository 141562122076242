import React, { useState, useEffect, useRef } from 'react'

import { withTranslation } from 'react-i18next'
import { FormControl, Button, InputLabel, Select, MenuItem, Card, Grid, Typography, TextField, ListItem, Checkbox, ListItemText } from '@material-ui/core'
import { Dialog, List,IconButton, Chip, Input, Paper } from '@material-ui/core'
import { ListItemIcon } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'

import Loading from '../../../Loading/Loading'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuprops = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        },
    },
};

export const DropDownInputContext = React.createContext('DropDownInputContext');

function CustomList(props) {
  const {
    items,
    checked,
    onToggle,
  } = props;

  return (
    <Card style={{
      width: 450,
      height: 230,
      overflowY: 'scroll'}}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={() => onToggle(value.id)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.labels.join(" ")} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  )
}

function MultiPickerSelector(props) {
  const {
    items,
    assigned,
    onSave,
    inputLabel,
  } = props;

  const allItemIds = items.map(item => item.id);
  const initialLeft = allItemIds.filter(id => assigned.indexOf(id) < 0);

  const [checked, setChecked] = useState([]);
  const [filter, setFilter] = useState("");
  const [currentLeft, setCurrentLeft] = useState([...initialLeft]);
  const [currentRight, setCurrentRight] = useState([...assigned]);

  const onClear = () => {
    setChecked([]);
  }

  const handleSave = () => {
    const selectedItems = items.filter(item => currentRight.indexOf(item.id) >= 0);
    onSave(selectedItems);
  }

  const handleToggle = (id) => {
    var newChecked = [...checked];

    const index = checked.indexOf(id);
    if (index < 0) {
      newChecked.push(id);
    } else {
      newChecked.splice(index, 1);
    }
    setChecked(newChecked);
  }

  const handleChecked = (start, setStart, end, setEnd, checked) => {
    var checkedStart = start.filter(v => (checked.indexOf(v) >= 0));
    var newCurrentStart = [...start];

    newCurrentStart = newCurrentStart.filter(v => (checkedStart.indexOf(v) < 0));
    setStart(newCurrentStart);

    var newCurrentEnd = [...end, ...checkedStart];
    setEnd(newCurrentEnd);
  }

  const handleCheckedRight = () => {
    handleChecked(currentLeft, setCurrentLeft, currentRight, setCurrentRight, checked);
  }

  const handleCheckedLeft = () => {
    handleChecked(currentRight, setCurrentRight, currentLeft, setCurrentLeft, checked);
  }

  const buildFilterRegexp = () => {
    return RegExp(filter, "i");
  }

  const filterWith = (item, filter) => {
    return (
      !filter ||
      item.labels.some(label => label.match(buildFilterRegexp()))
    );
  }

  return (
    <div style={{overflow:"hidden",width:1200,height:520}}>
      <Typography variant="h6" style={{textAlign:"center",margin:"3%"}}>{inputLabel}</Typography> 
      <TextField style={{marginLeft:"7.5%", width:440}} label="Filter" onChange={e => setFilter(e.currentTarget.value)}/>                
      <Grid container spacing={8} justify="center" alignItems="center">
        <Grid style={{margin:"2%"}} item><CustomList items={items.filter(item => currentLeft.indexOf(item.id) >= 0).filter(item => filterWith(item, filter))} checked={checked} onToggle={handleToggle}/></Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <IconButton
                variant="outlined"
                size="small"
                onClick={onClear}
                disabled={checked.length === 0}
              >
                <DeleteIcon />
            </IconButton>
            <Button
              style={{margin:"2%"}}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              aria-label="move selected right"
            >
            &gt;
            </Button>
            <Button
              style={{margin:"2%"}}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              aria-label="move selected left"
            >
            &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid style={{margin:"2%"}} item><CustomList items={items.filter(item=>currentRight.indexOf(item.id) >= 0)} checked={checked} onToggle={handleToggle}/></Grid>
      </Grid>        
      <Button onClick={handleSave} style={{margin:"2%",float:"right",position:"relative"}} color="primary" variant="contained">Save</Button>        
    </div>
  )
}

function createDataItem(item, labelTransformers) {
  return {
    id:item.id,
    item: item,
    labels: labelTransformers.map(transformer => String(transformer(item)).toLocaleLowerCase()),
  }
}

function MultiPicker(props) {
  const { 
    classes,
    label,
    id,
    t,
    required,
    query,
    formState,
    queryName,
    labelTransformer,
    inputId,
    queryItemIterator,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(formState.displayData[inputId] ? formState.displayData[inputId].map(item=>(createDataItem(item, labelTransformer))) : []);

  const initialRender = useRef(true);

  const error =  Boolean(formState.errors[id]);

  useEffect(()=> {
    if (initialRender.current) {
      initialRender.current=false;
    } else {
      setSelectedData([]);
    }
  }, props.watchedStateIdentifierForSelectionClear ? [formState.mappedData[props.watchedStateIdentifierForSelectionClear]] : []);

  
  const createDataItems = (items) => {
    var itemsData = queryItemIterator ? queryItemIterator(items) : items.edges.map(edge => edge.node);

    return itemsData.map(itemData => createDataItem(itemData, labelTransformer))
  }

  const getQueryVariables = () => {
    return props.queryVariablesTransformer ? props.queryVariablesTransformer(formState.mappedData) : null;
  }

  const handleSave = (selectedItems) => {
    const ids = selectedItems.map(item=> parseInt(item.id));
    const labels = selectedItems.map(item=>item.item);
    props.onChangeHandler(props.inputId, Object(ids), labels);
    setSelectedData(selectedItems);
    setDialogOpen(false);
  }

  const inputLabel = required ? required(t(label)) : t(label);
  const handleOpenDialog = () => {
      setDialogOpen(true);
  }

  return(
    <div>
      <div>
        <FormControl style={{width:250}} className={classes.formControl}>
          <InputLabel htmlFor={label} error={error}>{inputLabel}</InputLabel>
          <Select
            value={" "}
            IconComponent={SearchIcon}
            onOpen={handleOpenDialog}
            open={false}
            menuprops={menuprops}
            style={{width:'100%'}}
            className={classes.input}
            id={id}
            input={<Input />}
            error={error}
          >
            <MenuItem key={''} value={null} className={classes.input}> - </MenuItem>
          </Select>
        </FormControl>
        <Paper elevation={1} style={{width:250,margin:"-8px 0px 8px 8px"}}>
          <div style={{padding:10, overflow:'auto', maxHeight:250}}>
            {selectedData.length > 0 ? 
              <>
                {
                  selectedData.map(item =>
                    <Chip
                      style={{margin:2}}
                      color={'primary'}
                      size='small'
                      variant='outlined'
                      key={item.id}
                      label={item.labels[0]}
                    />
                  )
                }
              </>
            :
              <Chip style={{margin:2}}
              color={'primary'}
              size='medium'
              variant='outlined'
              label={"Unassigned"}
              />
            }

          </div>
        </Paper>
      </div>
      <Dialog
        open={dialogOpen}
        maxWidth='lg'
        scroll="paper"
        onClose={()=>{setDialogOpen(false)}}
        id={'pickerDialog'+ label }
        
      >
        <span onClick={()=>{setDialogOpen(false)}} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>
        <Loading query={query} variables={getQueryVariables()}>
          {(data)=> { 
            const itemsData = createDataItems(data[queryName]);

            return(
              <MultiPickerSelector items={itemsData} assigned={selectedData.map(item => String(item.id))} onSave={handleSave} inputLabel={inputLabel}/>
            )
          }}
        </Loading>
      </Dialog>
    </div>
  )
}

export default withTranslation("MultiPicker")(MultiPicker)